const LinkPopupStyles = (theme) => ({
    addLinkField: {
        "& .MuiOutlinedInput-input": {
            fontSize: "12px",
            fontWeight: 500,
            color: `${theme?.palette?.editor?.textColor} !important`,
        },
        "& .MuiFormHelperText-root": {
            color: `${theme?.palette?.editor?.textColor} !important`,
        },
        "& .MuiOutlinedInput-root": {
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.16)",
            color: `${theme?.palette?.editor?.textColor} !important`,
            borderRadius: "7px",
            "& fieldset": {
                borderColor: "#D8DDE1",
            },
            "&:hover fieldset": {
                borderColor: "#64748B",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#2563EB",
            },
            "& .MuiFormLabel-root": {},
        },
    },
});

export default LinkPopupStyles;