import html2canvas from "html2canvas";
import { rectIntersection, closestCenter } from "@dnd-kit/core";
import { getQueryStrings } from "../utils/SlateUtilityFunctions";
import { Node } from "slate";
const HIDE_PLACHOLDERS = ["grid", "grid-item", "table"];
const PREVIEW_IMAGE_HIDE_CLASS = [
  "grid-container-toolbar",
  "grid-item-toolbar",
  "element-toolbar",
  "mini-tool-wrpr-ei",
  "element-selector",
  "element-selector-ctrl",
];

export const getThumbnailImage = async (dom, options = {}) => {
  try {
    const canvas = await html2canvas(dom, {
      windowWidth: 1440,
      windowHeight: 768,
      ...options,
      backgroundColor: null,
      allowTaint: true,
      useCORS: false,
      scale: 0.5,
      imageTimeout: 0,
      onclone: (document) => {
        // hide class
        const sw = document.getElementById("slate-wrapper-scroll-container");
        sw.style.minHeight = "2000px";

        const svgFrames = document.querySelectorAll(".image-frame svg");
        for (let i = 0; i < svgFrames.length; i++) {
          svgFrames[i].style.width = "100%";
        }

        const rectFills = document.getElementsByTagName("rect");
        for (let i = 0; i < rectFills.length; i++) {
          const hasURL =
            rectFills[i]?.getAttribute("fill")?.indexOf("url") > -1;
          if (hasURL) {
            rectFills[i].style.fill = "#CCC";
          }
        }

        for (let hidedeClass of PREVIEW_IMAGE_HIDE_CLASS) {
          for (let element of document.getElementsByClassName(hidedeClass)) {
            element.style.display = "none";
          }
        }
        return document;
      },
    });
    return canvas.toDataURL("image/jpeg", 0.5);
  } catch (err) {
    console.log(err);
    return null;
  }
};

export function customCollisionDetectionAlgorithm(args) {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions = closestCenter(args);

  // Collision detection algorithms return an array of collisions
  if (pointerCollisions.length >= 0 && rectIntersection(args)) {
    return rectIntersection(args);
  }

  // If there are no collisions with the pointer, return rectangle intersections
  return [];
}

function componentToHex(c) {
  var hex = Number(c).toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(rgb) {
  return "#" + rgb.match(/\d+/g).map(componentToHex).join("");
}

export function invertColor(hex) {
  try {
    if (hex?.indexOf("#") === -1) {
      hex = rgbToHex(hex);
    }

    if (hex?.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex?.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (hex?.length !== 6) {
      // throw new Error("Invalid HEX color.");
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b) + "80";
  } catch (err) {
    console.log(err);
    return "#FFF";
  }
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export function getEmbedURL(element, needType = false) {
  let refUrl = element.href ? element.href : element.url;

  refUrl = refUrl ? (refUrl.includes("http") ? refUrl : `//${refUrl}`) : "Link";

  let embedUrl = refUrl;
  let isEmbed = false;

  if (embedUrl.includes("youtube")) {
    isEmbed = true;
    embedUrl = getQueryStrings(embedUrl);
  }
  if (embedUrl.includes("youtu.be")) {
    isEmbed = true;
    embedUrl = getQueryStrings(embedUrl);
  }
  if (embedUrl.includes("loom")) {
    isEmbed = true;
    embedUrl = embedUrl.replace(/\/share\//, "/embed/");
  }

  if (embedUrl.includes("vimeo")) {
    isEmbed = true;
    embedUrl = embedUrl.replace(/\/vimeo.com\//, "/player.vimeo.com/video/");
  }
  if (embedUrl.includes("dailymotion") && embedUrl.includes("video")) {
    isEmbed = true;
    embedUrl = embedUrl.replace(
      /www.dailymotion.com\//,
      "www.dailymotion.com/embed/"
    );
  }

  if (embedUrl.includes("dai.ly")) {
    isEmbed = true;
    embedUrl = embedUrl.replace(/dai.ly\//, "www.dailymotion.com/embed/video/");
  }

  return needType ? { embedUrl, isEmbed } : embedUrl;
}

export const isEmptyTextNode = (element) => {
  try {
    if (element) {
      const showPlaceholder =
        element?.children?.filter((f) => HIDE_PLACHOLDERS.indexOf(f.type) > -1)
          .length === 0;
      return Node.string(element)?.length === 0 && showPlaceholder;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const debounce = function (func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments,
      later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      },
      callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
