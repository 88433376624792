import React, { useCallback, useRef, useState, useEffect } from "react";
import { createEditor } from "slate";
import { Slate, Editable } from "slate-react";
import { useDebounce } from "use-debounce";
import { getBlock, getMarked } from "./utils/SlateUtilityFunctions";
import { commands, mentionsEvent } from "./utils/events";
import useMentions from "./hooks/useMentions";
import Shorthands from "./common/Shorthands";
import BasicToolbar from "./Toolbar/Basic";
import withCommon from "./hooks/withCommon";
import { serializeToText } from "./utils/serializeToText";
import "./Editor.css";

const MiniEditor = (props) => {
  const {
    id,
    content,
    handleEditorChange,
    // onSave,
    // editor: collaborativeEditor,
    readOnly,
    miniEditorPlaceholder,
    className,
    otherProps,
    onSave,
    theme
  } = props;

  const {
    CHARACTERS = [],
    // needLayout = true,
  } = otherProps || {};
  const mentionsRef = useRef();

  const [editor] = useState(() =>
    withCommon(createEditor(), { needLayout: false })
  );
  const [isInteracted, setIsInteracted] = useState(false);
  const [value, setValue] = useState(content);
  const [deboundedValue] = useDebounce(value, 500);
  const isReadOnly = readOnly === "readonly";
  const customProps = {
    ...(otherProps || {}),
    readOnly: isReadOnly,
    editorPlaceholder: miniEditorPlaceholder,
    page_id: id,
  };

  const [mentions, setMentions] = useMentions({
    editor,
    selection: editor?.selection,
  });

  const { search, target, type } = mentions;
  const chars = type ? Shorthands[type]({ ...mentions, CHARACTERS }) : [];

  useEffect(() => {
    if (onSave && isInteracted) {
      const { value: strVal, ...restVal } = getOnSaveData(deboundedValue);
      onSave(strVal, restVal);
    }
  }, [deboundedValue]);

  const getOnSaveData = (val) => {
    const text = serializeToText(val);
    const title = val?.find((f) => f.type === "title");
    return {
      value: JSON.stringify(val),
      text: text,
      title: serializeToText(title?.children) || "Untitled",
    };
  };

  const Leaf = ({ attributes, children, leaf }) => {
    children = getMarked(leaf, children);
    return <span {...attributes}>{children}</span>;
  };

  const Element = (props) => {
    return getBlock(props);
  };

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} customProps={customProps} />;
  }, []);

  const onKeyDown = useCallback(
    (event) => {
      const isMetaKey =
        event.metaKey && event.keyCode >= 65 && event.keyCode <= 90;
      const isCtrlKey = event.ctrlKey || isMetaKey;
      if (target && chars.length > 0 && !isCtrlKey) {
        mentionsEvent({
          event,
          mentions,
          setMentions,
          chars,
          target,
          editor,
          type,
          mentionsRef,
        });
      } else if (isCtrlKey) {
        commands({
          event,
          editor,
        });
      }
    },
    [chars, editor, target, mentions, setMentions, search, type, mentionsRef]
  );

  const renderElement = useCallback((props) => {
    return <Element {...props} customProps={customProps} />;
  }, []);

  const onChange = (newVal) => {
    if (!isInteracted) {
      setIsInteracted(true);
    }
    handleEditorChange(newVal);
    setValue(newVal);
  };

  return (
    <Slate editor={editor} initialValue={content} onChange={onChange} theme={theme}>
      <BasicToolbar {...props} />
      <Editable
        className={className || "mini-editor-cls"}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
      />
    </Slate>
  );
};

MiniEditor.displayName = "MiniEditor";

export default MiniEditor;
