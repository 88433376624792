import { Grid } from "@mui/material";
import React, { useRef } from "react";
import FormStyles from "./Styles";
import { useEditorContext } from "../../../hooks/useMouseMove";
import { MiniEditor } from "../../../..";

const UserInputs = (props) => {
  const {
    element,
    type,
    subject,
    handleField,
    onSave,
  } = props;
  const editorRef = useRef();
  const variables = element?.children;
  const { theme } = useEditorContext();
  const classes = FormStyles(theme);

  return (
    <Grid container sx={classes.toolbarContainer}>
      <Grid item xs={12} sx={classes.bodyTextArea}>
        {type === 2 && (
          <MiniEditor
            editorRef={editorRef}
            classes={classes}
            className={'editorWorkflow'}
            handleEditorChange={handleField}
            id={1}
            content={subject}
            onSave={onSave}
            miniEditorPlaceholder = {`Hey {{ field_name }} \n\nThanks for attending the event called {{ Event Name }} at {{ Event Time }} on {{ Event Time }}.`}
            otherProps={{ variableOptions: variables, fontStyleOptions: ['underline'], hideLink: true , hideTextColor: true}}
            theme={theme}
          />
        )}
      </Grid>
    </Grid>
)};

export default UserInputs;
