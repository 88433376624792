import { Button, Fade, Grid, Paper, Popper } from "@mui/material";
import SelectTypography from "./SelectTypography";
import SelectList from "./SelectList";
import { toolbarGroups } from "../../toolbarGroups";
import { MarkButton } from "../../FormatTools";
import { activeMark, isBlockActive } from "../../../utils/SlateUtilityFunctions";
import LinkButton from "../../../Elements/Link/LinkButton";
import TextToolIcon from "../../../assets/svg/TextToolIcon";
import TextFormat from "../TextFormat";
import { useState } from "react";
import useWindowResize from "../../../hooks/useWindowResize";
import PopperHeader from "../PopperHeader";
import MiniColorPicker from "./MiniColorPicker";
import SelectAlignment from "./SelectAlignment";
import SelectFontSize from "./SelectFontSize";
import InfinityAITool from "./InfinityAITool";

const DEFAULT_COLOR = {
    color: "#000000",
    bgcolor: "#FFFFFF",
};

const textColorFormat = "color";
const textBgFormat = "bgColor";

const allTools = toolbarGroups.flat();

const MiniTextFormat = (props) => {
    const { classes, editor, closeMainPopup, customProps, theme } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)
    const id = open ? "popup-edit-tool" : "";

    const [size] = useWindowResize();

    const removeFontStyles = ["superscript", "subscript"]
    const fontStyle = allTools.filter((f) => f.type === "mark" && !removeFontStyles.includes(f.format));
    const link = allTools.find((f) => f.format?.indexOf("link") >= 0);
    const fontAlign = allTools.filter((f) => f.format?.indexOf("align") >= 0);

    const activeColor = activeMark(editor, textColorFormat) || DEFAULT_COLOR[textColorFormat];
    const activeBg = activeMark(editor, textBgFormat) || DEFAULT_COLOR[textBgFormat];

    return (
      <Grid
        container
        sx={classes.miniTextFormatWrapper}
        alignItems={"center"}
        id="mini-text-editor-wrapper"
      >
        <Grid item xs={12}>
          <div className="toolWrapper">
            {customProps?.hideTools?.includes("infinityAI") ? null : (
              <InfinityAITool />
            )}
            <SelectTypography
              classes={classes}
              editor={editor}
              closeMainPopup={closeMainPopup}
            />
            <div className="verticalLine"></div>
            <SelectList classes={classes} editor={editor} />
            <div className="verticalLine mr-1"></div>
            <SelectAlignment
              fontAlign={fontAlign}
              classes={classes}
              editor={editor}
            />
            <div className="verticalLine mr-1"></div>
            <SelectFontSize classes={classes} editor={editor} />
            <div className="verticalLine mr-1"></div>

            {fontStyle?.map((m, i) => {
              return (
                <MarkButton
                  key={`pptool_mark_${i}_${m.id}`}
                  editor={editor}
                  {...m}
                />
              );
            })}

            <MiniColorPicker
              format={textColorFormat}
              classes={classes}
              activeColor={activeColor}
              id="11_cc"
              editor={editor}
            />

            <MiniColorPicker
              format={textBgFormat}
              classes={classes}
              activeColor={activeBg}
              id="12_cc"
              editor={editor}
            />

            <div className="verticalLine ml-1 mr-1"></div>

            <LinkButton
              key={link.id}
              active={isBlockActive(editor, link.format)}
              editor={editor}
              theme={theme}
            />

            <Button
              onClick={(e) =>
                setAnchorEl(document.getElementById("mini-text-editor-wrapper"))
              }
              className="textSettingsIcon"
            >
              <TextToolIcon />
            </Button>

            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              transition
              placement="bottom-end"
              sx={classes.popupWrapper}
              className={`tools-drawer ${size?.device} textSettings`}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Paper style={{ borderRadius: "10px" }}>
                    <PopperHeader
                      title={"Text Settings"}
                      classes={classes}
                      onClose={() => setAnchorEl(null)}
                    />
                    <TextFormat editor={editor} classes={classes} />
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        </Grid>
      </Grid>
    );
}

export default MiniTextFormat;